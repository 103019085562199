var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.permissions.c)?_c('button-top',{attrs:{"must-icon":false,"tooltip":"Crear producto","name":"Crear"},on:{"action":_vm.store}}):_vm._e(),_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"specie_id"}},[_vm._v("Especie \\ Nombre comun")]),_c('validation-provider',{attrs:{"name":"Especie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"fl",attrs:{"id":"specie_id","value-field":"id","text-field":"name","options":_vm.list.species,"state":errors.length > 0 ? false : null,"name":"specie_id"},model:{value:(_vm.form.specie_id),callback:function ($$v) {_vm.$set(_vm.form, "specie_id", $$v)},expression:"form.specie_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"packaging_id"}},[_vm._v("Empaque")]),_c('validation-provider',{attrs:{"name":"Empaque","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"fl",attrs:{"id":"packaging_id","value-field":"id","text-field":"packaging_name","options":_vm.list.packagings,"state":errors.length > 0 ? false : null,"name":"packaging_id"},model:{value:(_vm.form.packaging_id),callback:function ($$v) {_vm.$set(_vm.form, "packaging_id", $$v)},expression:"form.packaging_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"name"}},[_vm._v("Altura (Cm)")]),_c('validation-provider',{attrs:{"name":"Altura  (Cm)","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"height","type":"number","state":errors.length > 0 ? false : null,"name":"height"},model:{value:(_vm.form.height),callback:function ($$v) {_vm.$set(_vm.form, "height", $$v)},expression:"form.height"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"name"}},[_vm._v("Días producción")]),_c('validation-provider',{attrs:{"name":"Peso","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"production_days","type":"number","state":errors.length > 0 ? false : null,"name":"production_days"},model:{value:(_vm.form.production_days),callback:function ($$v) {_vm.$set(_vm.form, "production_days", $$v)},expression:"form.production_days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"name"}},[_vm._v("Precio")]),_c('validation-provider',{attrs:{"name":"Peso","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"price","type":"number","state":errors.length > 0 ? false : null,"name":"price"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"DollarSignIcon","size":"16"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"name"}},[_vm._v("Descripcion")]),_c('validation-provider',{attrs:{"name":"Descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","rows":"3","max-rows":"6"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }