<template>
  <div>
    <button-top v-if="permissions.c" :must-icon="false" tooltip="Crear producto" name="Crear"
      @action="store"></button-top>
    <validation-observer ref="form">
      <b-form>

        <b-row>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="specie_id" class="text-success">Especie \ Nombre comun</label>
              <validation-provider #default="{ errors }" name="Especie" rules="required">
                <b-form-select id="specie_id" class="fl" v-model="form.specie_id" value-field="id" text-field="name"
                  :options="list.species" :state="errors.length > 0 ? false : null" name="specie_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="packaging_id" class="text-success">Empaque</label>
              <validation-provider #default="{ errors }" name="Empaque" rules="required">
                <b-form-select id="packaging_id" class="fl" v-model="form.packaging_id" value-field="id"
                  text-field="packaging_name" :options="list.packagings" :state="errors.length > 0 ? false : null"
                  name="packaging_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Altura (Cm)</label>
              <validation-provider #default="{ errors }" name="Altura  (Cm)" rules="required|double">
                <b-form-input id="height" type="number" v-model="form.height" :state="errors.length > 0 ? false : null"
                  name="height" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Días producción</label>
              <validation-provider #default="{ errors }" name="Peso" rules="required|numeric">
                <b-form-input id="production_days" type="number" v-model="form.production_days"
                  :state="errors.length > 0 ? false : null" name="production_days" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Precio</label>
              <validation-provider #default="{ errors }" name="Peso" rules="required|numeric">
                <b-input-group>
                  <b-form-input id="price" type="number" v-model="form.price" :state="errors.length > 0 ? false : null"
                    name="price" />
                  <b-input-group-append is-text>
                    <feather-icon icon="DollarSignIcon" size="16" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Descripcion</label>
              <validation-provider #default="{ errors }" name="Descripcion" rules="required">
                <b-form-textarea id="description" v-model="form.description" :state="errors.length > 0 ? false : null"
                  name="description" rows="3" max-rows="6"></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      form: {
        specie_id: '',
        packaging_id: 0,
        height: 0,
        production_days: 0,
        price: 0,
        description: '',
      },
      list: {
        species: [],
        packagings: [],
      }
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      await this.getPermission('c');
      await this.getPermission('u');
      await this.get();
    },

    async get() {
      try {
        this.loading();
        this.list.species = (await this.$http.get('production/specie/list')).data.data;
        this.list.packagings = (await this.$http.get('production/packaging/index')).data.data;

      } catch (err) {
        this.notify("Error", err.response.data.message, 'danger')
      } finally {
        this.not_loading();
      }
    },


    store() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading();
          this.$http
            .post('/production/product/store', this.form)
            .then(res => {
              this.notify("Proceso satisfactorio.", res.data.message, 'primary')
              this.$router.push(`/gestion-productos/show/${res.data.id}`)
            })
            .catch(err => {
              this.notify("Error", err.response.data.message, 'danger')
            }).finally(
              () => {
                this.not_loading();
              }
            )
        }
      }
      )
    },


  },


}
</script>